import React, { useContext, useState } from "react";
import Collapsible from "react-collapsible";
import dataAsked from "../AskedQuestions";
import { Container } from "@mui/material";
import Example from "../Components/Carousel";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
import { AppContext } from "../App";

const AskedQuestions = () => {
  const contactInfo = useContext(AppContext)?.contactInfo?.[0];

  const Faq = FetchData("questions");
  const pages = FetchData("pages/18");
  return (
    <>
      {Faq !== null && pages !== null ? (
        <>
          <Example title={pages?.pages[0].title} carosal="yes" arr={pages?.pages[0].photos} mg={true} />
          <Container maxWidth="md" className="">
            <p
              className="centerText mb-5"
              style={{
                textAlign: "center",
                fontSize: "17px",
                color: "#2F3D4C",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: pages?.pages[0].description,
                }}
                className="habd"
              ></div>
            </p>
            <div>
              {Faq?.Overviews.map((item, index) => {
                return (
                  <Collapsible
                    key={index}
                    trigger={`${index + 1}. ${item.question}`}
                    className="snell"
                    style={{ padding: "20px 30px", fontSize: "30px" }}
                    transitionTime={200}
                    triggerClassName="collapsible-trigger"
                    triggerOpenedClassName="collapsible-trigger collapsible-open"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                      className="habd"
                    ></div>
                  </Collapsible>
                );
              })}
            </div>
            <div className="flex my-5">
              <div style={{ marginRight: "30px" }}>
              <img src={contactInfo?.qr1} alt="" style={{width:'100px'}}/>
                <p style={{ textAlign: "center" }}>Aswan</p>
              </div>
              <div>
              <img src={contactInfo?.qr2} alt="" style={{width:'100px'}}/>
                <p style={{ textAlign: "center" }}>Luxor</p>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default AskedQuestions;
