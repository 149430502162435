import React from "react";
import AccomodationBooking from "../Components/AccomodationBooking";
import { Container, Grid } from "@mui/material";
import Example from "../Components/Carousel";
import cabinsData from "../CabinsDataDesc";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const AccomoCategories = (props) => {
  const cat = ["/cabina.png", "/Acco3.png", "/Acco3.png", "Acco4.png"];
  const dataHead = FetchData("pages/21");
  const cabins = FetchData("allcabins");
  return (
    <>
      {dataHead !== null && cabins !== null ? (
        <div>
          <Example title={dataHead?.pages[0].title} carosal="yes" arr={dataHead?.pages[0].photos} mg={true} />

          <Container maxWidth="md" className="">
            <p style={{ color: "#2f3d4c", fontSize: "18px" }} className="centerText">
              <div
                dangerouslySetInnerHTML={{
                  __html: dataHead?.pages[0].description,
                }}
              ></div>
            </p>
          </Container>
          <Container maxWidth="lg" className="mt-5">
            <AccomodationBooking handleOpen={props.handleOpen} cabins={cabins} />
          </Container>
        </div>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default AccomoCategories;
