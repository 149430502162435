import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, createContext, useEffect, useState } from "react";
import { Routes, BrowserRouter, Route, useLocation } from "react-router-dom";
import LayOut from "./LayOut/LayOut";
import MainForHomePage from "./Pages/HomePage";
import "./i18n";
import "./family.css";
import Accomodation from "./Pages/Accomodation";
import Dining from "./Pages/Dining";
import AccomoCategories from "./Pages/AccomoCategories";
import ContactUs from "./Pages/ContactUs";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Itineraries from "./Pages/Itineraries";
import Escapeds from "./Pages/Escapeds";
import MediaCenter from "./Pages/MediaCenter";
import AskedQuestions from "./Pages/AskedQuestions";
import RelaxAndUnwind from "./Pages/RelaxAndUnwind";
import DockPlan from "./Pages/DockPlan";
import Inspiration from "./Pages/Inspiration";
import FetchData from "./FetchData";
import axios from "axios";
import i18n from './i18n';
export const AppContext = createContext();
function App() {
  const [AcomoSwitch, setacomoSwitch] = useState(true);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [weather, setWeather] = React.useState();
  const getWeather = async () => {
    const response = await axios.get(
      "https://api.openweathermap.org/data/2.5/weather?q=Aswan&appid=e249c1e7ebb5a267b04d24f4a8d85a21&units=metric"
    );
    setWeather(response.data);
  };

  React.useEffect(() => {
    getWeather();
    const mobileLanguage = i18n.language;
const supportedLanguages = ['en','en-US', 'pt','es']; // Add more supported languages

    if (!supportedLanguages.includes(mobileLanguage)) {
      i18n.changeLanguage('en');
    }
  }, []);
  const open2 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const [path1, setpath] = useState("");
  const path = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [path.pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => {
    setAnchorEl1(null);
  };

  const contactInfo = FetchData("contact-info")?.["contact-info"];
  const socialLinks = FetchData("socials")?.socials;
  return (
    <AppContext.Provider value={{ contactInfo, weather, socialLinks }}>
      <div>
        <Suspense fallback="loading..">
          <LayOut
            anchorEl1={anchorEl1}
            setAnchorEl1={setAnchorEl1}
            open2={open2}
            handleClick1={handleClick1}
            handleClose2={handleClose2}
            AcomoSwitch={AcomoSwitch}
            setacomoSwitch={setacomoSwitch}
            handleOpen={handleOpen}
            handleClose={handleClose}
            open={open}
          >
            <Routes>
              <Route exact path="/" element={<MainForHomePage />} anchorEl1={anchorEl1} />
              <Route
                exact
                path="/Accomodation"
                element={
                  <AccomoCategories
                    AcomoSwitch={AcomoSwitch}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    open={open}
                  />
                }
              />
              <Route
                exact
                path="/Accomodation/:id"
                handleOpen={handleOpen}
                element={<Accomodation AcomoSwitch={AcomoSwitch} handleOpen={handleOpen} />}
              />
              <Route exact path="/Dining" element={<Dining />} />
              <Route exact path="/ContactUs" element={<ContactUs />} />
              <Route exact path="/Itineraries/Inspiration" element={<Inspiration />} />
              <Route exact path="/Experience" element={<RelaxAndUnwind />} />
              <Route exact path="/Itineraries" element={<Itineraries />} />
              <Route exact path="/Escapeds" element={<Escapeds handleOpen={handleOpen} />} />
              <Route exact path="/TermsAndConditions" element={<TermsAndConditions />} />
              <Route exact path="/MediaCenter" element={<MediaCenter />} />
              <Route exact path="/FrequentlyAsked" element={<AskedQuestions />} />
              <Route exact path="/DockPlan" element={<DockPlan />} />
            </Routes>
          </LayOut>
        </Suspense>
      </div>
    </AppContext.Provider>
  );
}

export default App;
