import React from "react";

const Loading = () => {
  return (
    //     <h1 class>
    //     <span class="let1">l</span>
    //     <span class="let2">o</span>
    //     <span class="let3">a</span>
    //     <span class="let4">d</span>
    //     <span class="let5">i</span>
    //     <span class="let6">n</span>
    //     <span class="let7">g</span>
    //   </h1>
    <>
     <span class="loader"></span>
    </>
  );
};

export default Loading;
