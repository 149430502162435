import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { AiFillLinkedin, AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { BiLogoFacebook, BiLogoTwitter } from "react-icons/bi";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdPhone, MdEmail, MdNetworkCell } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { AppContext } from "../App";
import { TiZoomOutline } from "react-icons/ti";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const contactInfo = useContext(AppContext)?.contactInfo?.[0];
  const socialLinks = useContext(AppContext)?.socialLinks;
  const getIcon = (name) => {
    if (name === "Facebook") {
      return <BiLogoFacebook />;
    }
    if (name === "Instagram") {
      return <AiOutlineInstagram />;
    }
    if (name === "LinkedIn") {
      return <AiFillLinkedin />;
    }
    if (name === "Twitter") {
      return <FaXTwitter />;
    }
    if (name === "Youtube") {
      return <AiFillYoutube />;
    }
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        className="footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: "20px",
        }}
      >
        <Grid item xs={7} sm={6} md={2.4} className="">
          <img src="/iconFoot.png" width={80} alt="" />
        </Grid>
        <Grid item xs={12} sm={6} md={3} justifyContent="center" alignItems="center">
          <ul className="w-100 m-0">
            {Array.isArray(t("Footer1", { returnObjects: true })) &&
              t("Footer1", { returnObjects: true }).map((ele) => {
                return (
                  <li className="my-1">
                    <Link
                      to={
                        ele.item === t("Footer1.0.item")
                          ? "MediaCenter"
                          : ele.item === t("Footer1.1.item") && "FrequentlyAsked"
                      }
                      style={{ color: "#E8E5E2", fontSize: "20px" }}
                    >
                      {ele.item}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <ul className="my-0">
              {Array.isArray(t("Footer2", { returnObjects: true })) &&
                t("Footer2", { returnObjects: true }).map((ele) => {
                  return (
                    <li className="my-1">
                      <Link
                        to={
                          ele.item === t("Footer2.0.item")
                            ? "TermsAndConditions"
                            : ele.item === t("Footer2.1.item") && "ContactUs"
                        }
                        style={{ color: "#E8E5E2" }}
                      >
                        {ele.item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <p className=" nc">
              <a href={`tel: ${contactInfo?.phone}`} style={{ color: "#EEEBE2" }}>
                <MdPhone /> {contactInfo?.phone}
              </a>
            </p>
            {/* <p className=" nc">
              <a style={{ color: "#EEEBE2" }}>
              <TiZoomOutline/> {contactInfo?.working_hours}
              </a>
            </p> */}
            <p className=" nc">
              <a href={`mailto: ${contactInfo?.email}`} style={{ color: "#EEEBE2" }}>
                <MdEmail /> {contactInfo?.email}
              </a>
            </p>
            <p style={{ color: "#EEEBE2" }} className=" my-1 nc">
              {t("Footer3")}
            </p>
            <div className="flex px-0 nc" style={{ justifyContent: "flex-start" }}>
              {socialLinks?.map((item, index) => {
                return (
                  <div className="iconsStyle">
                    <a style={{ color: "#444" }} href={item?.value} target="_blank">
                      {getIcon(item?.name)}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
