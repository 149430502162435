import React from "react";
import { Link } from "react-router-dom";

const ItinerariesComp = ({ image, desc, ins, esc }) => {
  console.log(ins, esc, "kareeeeeeeeeeeeem");
  return (
    <div style={{ width: "100%", height: "", overflow: "hidden" }}>
      <div
        style={{
          width: "100%",
          height: "400px",
          overflow: "hidden",
          borderRadius: "30px",
          marginTop: "2.6px",
        }}
      >
        <Link to={`${ins ? "Inspiration" : "/Escapeds"}`}>
          <img
            src={ins ? ins.Inspirations[0].photos[0].photo : esc.escapades[0].photos[0].photo}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            className="anime"
          />
        </Link>
      </div>
      <div style={{ margin: "auto", width: "230px", fontSize: "18px" }} className="my-3">
        <p className="centerText">{ins ? ins.Inspirations[0].title : esc.escapades[0].title}</p>
      </div>
    </div>
  );
};

export default ItinerariesComp;
