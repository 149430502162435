import React from "react";
import Example from "../Components/Carousel";
import { Container } from "@mui/material";
import DiningComponents from "../Components/DiningComponents";
import data from "../ExpData";
import insdata from "../Inspiration";
import { MdEmail } from "react-icons/md";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
const Inspiration = () => {
  const data = FetchData("inspirationsDetails");
  const pages = FetchData("pages/16");
  return (
    <>
      {data !== null && pages !== null ? (
        <div>
          <Example arr={pages?.pages[0].photos} carosal="yes" title={pages?.pages[0].title} description="no" />
          {data !== null ? (
            <>
              <Container maxWidth="lg" className="my-4">
                {data?.inspirationsDetails.map((item, index) => {
                  return (
                    <DiningComponents
                      image={item.photos}
                      description={item.description}
                      title={item.title}
                      index={index}
                    />
                  );
                })}
              </Container>
              <div className="flex my-3">
                <MdEmail size={25} />
                &nbsp;
                <span style={{ color: "#28374e" }}>
                  Contact us at &nbsp;
                  <a href="mailto:smt@aqua-cruises.com?subject = Feedback&body = Message  ">smt@aqua-cruises.com </a>
                  to tailor your AQUA journey{" "}
                </span>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default Inspiration;
