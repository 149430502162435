const dataAsked = [
  {
    title: "Make a Booking",
    description: [
      "  Sailing dates are scheduled every Sunday from Luxor to Aswan for five nights and every Friday from Aswan to Luxor for two nights." ,
    "For a seamless cruising experience, a tailored monumental tour and sightseeing can be arranged for charter bookings at different sailing dates, subject to availability during booking time.",
   "Please contact us at SMT@Aqua-cruises.com and our Reservations team would be delighted to assist with your bookings and transfer arrangements."
],
  },
  {
    title: "Children Policy",
    description: [
      "Children are welcome at AQUA the Dahabeya. Minors must be accompanied by a parent or guardian over the age of 21 years old.",
      "Babysitting services are not available on board.",
    ],
  },
  {
    title: "Booking Modification or Cancellation",
    description: [
      "Kindly refer to the confirmation email; you will find an edit button to modify or cancel your reservation or communicate with our Reservations Team at SMT@aqua-cruises.com.",
      "Please note that a cancellation fee may apply.",
    ],
  },
  {
    title: "Airport Meet and Assist",
    description: [
      "If you booked airport transfers with us, our representative will be available to guide you to the arranged vehicle, assist with your luggage, and other airport pickup and drop-off procedures.",
    ],
  },
  {
    title: "Passports / Visa",
    description: [
      "Passports are required for all guests and must be valid for six months after the voyage’s completion date.",
      "In addition, guests should have visas and any other necessary documents according to Egyptian laws and regulations.",
    ],
  },
  {
    title: "Arrival And Departure Timing",
    description: [
      "Check-in will open for guests at 12:00 PM",
      "Departure day checkout: 08:00 AM",
      "We understand that arrival and departure times may vary according to the flight timing; please coordinate with the management team in case of early arrival or late departure for availability.",
      "In this case, guests are welcome to enjoy the public areas while waiting with most services open for their comfort.",
    ],
  },
  {
    title: "Luggage",
    description: [
      "Our cabins and suites are equipped with all amenities to allow guests to travel light.",
      "Luggage restrictions and fees can vary by airline, class of service, and destination or departure airport.",
      "For the most up-to-date luggage allowance policies and weight/size restrictions by airlines, we suggest you visit the websites of the selected airlines for your trip, most of which are updated regularly with the latest policies.",
      "All luggage must be securely packed and clearly labeled with your full name.",
      "Carry your Jewelry, fragile items, valuables, and important documents in your hand luggage.",
      "Upon arrival, our crew will affix luggage labels with your name and deliver the luggage directly to your luxury cabin or suite.",
    ],
  },
  {
    title: "On Call Doctor",
    description: [
      "Before sailing, we ask guests to ensure they are medically and physically fit for travel.",
      "A doctor will be on call.",
      "If the cruise is docked, the doctor may take up to 1 hour to arrive on board.",
      "However, first responders are available onboard in case of emergencies.",
    ],
  },
  {
    title: "Prohibited items",
    description: [
      "Guests are not allowed to bring onboard the following items:",
      "- Alcoholic or non-alcoholic beverages of any kind, including water, soda, and juices.",
      "- Unsealed food items will not be allowed on board.",
      "- Firearms, weapons of any kind, ammunition, explosive substances, or any goods of a dangerous nature.",
      "- Irons / Ironing Boards and Steamers.",
      "- Animals of any kind.",
      "- Large Batteries, Emergency Position Indicating Radio Beacons (EPIRB), ham radios, satellite phones, transformers, lasers, and laser pointers are not allowed onboard.",
    ],
  },
  {
    title: "Dietary Preferences",
    description: [
      "Our culinary team would be delighted to arrange any special dietary preference such as gluten-free, vegan food.",
      "Special requirements such as low sodium, or allergies should be communicated during booking time through your travel specialist or our Reservations Team at SMT@aqua-cruises.com.",
    ],
  },
  {
    title: "Money Matters",
    description: [
      "Most of the tourist establishments, sites, and souvenir shops accept US dollars. Having local currency fortipping or shopping in smaller souks or local restaurants is beneficial.",
      "There are no restrictions on the amount of foreign currency or travelers’ cheques entering the country. However, passengers entering Egypt with Egyptian currency exceeding the equivalent of USD 1,000 must declare this on arrival.",
      "Visa and Master cards are accepted on board.",
      "Egyptian Pound (LE) is the currency unit in Egypt, divided into 100 piasters. Notes are issued in 5, 10, 20, 50, 100, and 200-pounds denominations. Coins are issued in denominations of LE 1 and 50 piasters.",
      "All charges on board are in Egyptian pounds. Guests can pay in US Dollars, Euros, or Sterling. But please note: any change will be compensated in Egyptian pounds as no currency stock is available.",
    ],
  },
  {
    title: "Languages",
    description: [
      "The national language in Egypt is Arabic. However, tourists rarely find it a problem communicating as English is widely spoken in tourists’ sites and shops, in addition to Spanish and French as a second language.",
      "Many staff in tourist areas also speak German or Italian.",
    ],
  },
  {
    title: "Weather",
    description: [
      "Egypt has year-round sun and almost no rain except in Alexandria, which has a more Mediterranean climate than the rest of the country.",
      "Daytime temperatures range from pleasantly warm in the winter to very hot in the summer.",
      "Northerly cities such as Cairo and Alexandria are generally about 5ºC cooler than southerly areas such as Luxor, Aswan, and Abu Simbel.",
      "Nights in the desert can be significantly cold.",
    ],
  },
  {
    title: "Dress Code",
    description: [
      "The dress code style on board AQUA is set to match the relaxing lifestyle of a cruising vacation.",
      "During the day for breakfast and lunch, the dress code is casual, wherein shirts, shoes, and bathing suit cover-ups are required when not at the pool.",
      "During the evening, smart casual is required while dining.",
      "Ball caps, hats, shorts, and flip-flops are prohibited during dinner service.",
      "As in all countries with styles different from yours, it is courteous to respect local customs.",
      "Shorts and beachwear should be worn only at the beach or hotel pool.",
      "When visiting mosques, synagogues, and churches, women should wear modest dresses and avoid wearing strappy dresses, miniskirts, shorts, or low-necked dresses.",
      "Shoes are not allowed inside mosques; therefore, a thick pair of socks would protect you from floors.",
    ],
  },
  {
    title: "Gratuities",
    description: [
      "Gratuities can be extended on a voluntary individual basis according to guests’ discretion.",
    ],
  },
  {
    title: "Lost & Found",
    description: [
      "Guests who misplaced items can contact the Guest Services Desk. After the voyage, guests may email our team for assistance at SMT@aqua-cruises.com",
    ],
  },
  {
    title: "Smoking Policy",
    description: [
      "Smoking, including use of cigarettes, e-cigarettes, and cigars, is permitted only at the Sun Deck.",
    ],
  },
  {
    title: "Wheelchair & other Accessibility Information",
    description: [
      "Wheelchairs, an elevator, and a well-appointed accessible Aqua Luxury Cabin are available for guests with special needs.",
      "Guests requiring specific assistance while on board are asked to please notify our team at SMT@aqua-cruises.com.",
    ],
  },
];
export default dataAsked;
