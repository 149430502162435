import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fade } from "react-reveal";

const SliderAccomo = ({
  myArr,
  height,
 
}) => {
  const settings = {
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
   
    responsive: [
      {
        breakpoint: 1234,
        settings: {
          infinite: true,
          fade: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
         
        },
      },
      {
        breakpoint: 700,
        settings: {
          infinite: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        height: `${height ? height : "480px"}`,
        cursor: "pointer",
        margin: 0,
        overflow: "hidden",
      }}
    >
      <Slider
        {...settings}
        className={` SmallSlider`}
        style={{
          padding: "0px",
          height: "100%",
          width: "100%",
        }}
      >
        {myArr?.map((item) => {
          return (
            <div
              style={{
                height: `${height ? height : "800px"} `,
                width: "100%",
              }}
            >
              <Fade>
                <img
                  src={item.photo}
                  alt=""
                  height={"100%"}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                  // className={`${width === "w" && "w100"}`}
                />
              </Fade>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderAccomo;
