import { Container, Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";
import SmallCarosal from "./SmallCarosal";
import { useEffect } from "react";
import { useState } from "react";

const DiningComponents = ({ image, description, index, title }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth } = window;
    return innerWidth;
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    console.log(windowSize, "WINDOOOOOWWW");
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window.innerWidth]);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      style={{
        color: " #E8E5E2",
      }}
      className="Accomod"
      key={index}
    >
      {windowSize > 1099 ? (
        index % 2 !== 0 ? (
          <>
            <Grid item xs={12} sm={8} md={5.5} className="my-4" style={{ minHeight: "40vh" }}>
              <Fade>
                <SmallCarosal myArr={image} />
              </Fade>
            </Grid>
            <Grid item xs={12} sm={8} md={5.5} className="text-box">
              <h2 className="fonty">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={8} md={5.5} className="text-box  ">
              <h2 className="fonty">{title}</h2>
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </Grid>
            <Grid item xs={12} sm={8} md={5.5} className=" ">
              <SmallCarosal myArr={image} />
            </Grid>{" "}
          </>
        )
      ) : (
        <>
          <Grid item xs={12} sm={8} md={5.5} className="my-4" style={{ minHeight: "40vh" }}>
            <Fade>
              <SmallCarosal myArr={image} />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={8} md={5.5} className="text-box ">
            <h2 className="fonty">{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </Grid>
        </>
      )}
      {}
    </Grid>
  );
};

export default DiningComponents;
