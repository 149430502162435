import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Components/Footer";
import NavBar from "../Components/Navbar";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
const LayOut = ({
  children,
  anchorEl1,
  setAnchorEl1,
  open2,
  handleClick1,
  handleClose2,
  setacomoSwitch,
  AcomoSwitch,
  handleOpen,
  handleClose,
  open,
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [widthLog, setWidthLog] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const [navFade, setnavFade] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 160) {
        setnavFade(false);
        setWidthLog(true);
      } else {
        setnavFade(true);
        setWidthLog(false);
      }
    };
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 750,
        md: 1100,
        lg: 1400,
        xl: 1636,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="" style={{ width: "100%", position: "relative" }}>
        <NavBar
          handleDrawerToggle={handleDrawerToggle}
          anchorEl1={anchorEl1}
          setAnchorEl1={setAnchorEl1}
          open2={open2}
          handleClick1={handleClick1}
          handleClose2={handleClose2}
          setacomoSwitch={setacomoSwitch}
          AcomoSwitch={AcomoSwitch}
          navFade={navFade}
          width={widthLog}
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
        />
      </div>
      <div style={{ minHeight: "69vh" }}>{children}</div>
      <Footer />
    </ThemeProvider>
  );
};

export default LayOut;
