import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Media from "../MediaCenterGallery";
import data from "../ExpData";
import { Fade, Grid } from "@mui/material";
import ExperiencesCard from "./ExperiencesCard";
import FetchData from "../FetchData";

const HomeSlider = ({ data1 }) => {
  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    responsive: [
      {
        breakpoint: 1234,
        settings: {
          infinite: true,
          fade: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          infinite: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  console.log("DATA1", data1);
  return (
    <div
      style={{
        width: "100%",
        minHeight: "500px",
        cursor: "pointer",
      }}
    >
      <Slider {...settings} className="HomeSlider my-0">
        {data1?.experience.map(({ photos, shortdescription, title }, index) => {
          return (
            <>
              <ExperiencesCard img={photos[0]?.photo} description={shortdescription} title={title} />
            </>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomeSlider;
