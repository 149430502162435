import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Media from "../MediaCenterGallery";

const GallerySlider = ({ slideIndex }) => {
  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Enable center mode
    centerPadding: "0", // Remove padding around center slide
    initialSlide: slideIndex,
  };

  return (
    <div
      style={{
        width: "70vw",
        height: "fit-content",
        cursor: "pointer",
      }}
    >
      <Slider {...settings} className="GallerySlider">
        {Media.map((item) => {
          return (
            <div>
              <img
                src={item.image}
                style={{ width: "350px" }}
                alt=""
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default GallerySlider;
