import { Container, Grid } from "@mui/material";
import React from "react";
import Relax from "../RelaxWindData";
import Example from "../Components/Carousel";
import SmallCarosal from "../Components/SmallCarosal";
import dataAccomo from "../AccomoDataSliders";
import { Fade } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const RelaxAndUnwind = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth } = window;
    return innerWidth;
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    console.log(windowSize, "WINDOOOOOWWW");
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window.innerWidth]);
  const data1 = FetchData("experience");
  const dataHead = FetchData("pages/12");
  console.log("dataheaaad", dataHead);
  console.log(data1);
  const applyFontFamily = (text) => {
    const regex = /(Aqua Gym|Aqua Spa  )/g;
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.match(regex)) {
        return (
          <span key={index} className="snell">
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };
  return (
    <>
      {data1 !== null && dataHead !== null ? (
        <div>
          <Example title={dataHead?.pages[0].title} carosal="yes" arr={dataHead?.pages[0].photos} mg={true} />
          <Container maxWidth="sm" className="mb-4">
            <Fade>
              <div
                dangerouslySetInnerHTML={{
                  __html: dataHead?.pages[0].description,
                }}
                className={` coler`}
              ></div>
            </Fade>
          </Container>
          {/* <Container maxWidth="md" className="mt-4">
        <Grid item xs={12} sm={8} md={8}>
          <SmallCarosal myArr={dataAccomo} height={400} />
        </Grid>
      </Container> */}

          <Container maxWidth="lg" className="  my-4">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
              style={{
                color: " #E8E5E2",
              }}
              className="Accomod"
            >
              {/* <Grid item xs={12} sm={4} md={5}>
            <p
              className="centerText fonty"
              style={{ fontSize: "25px", margin: "0" }}
            >
              Pool and Lounge
            </p>
          </Grid>
          <Grid item xs={12} sm={8} md={8} className="mb-3">
            <Fade>
              <p style={{ color: "#2F3D4C", textAlign: "center" }}>
                A relaxing dip in Aqua the Dahabeya’s plunge pool while sailing
                is a soothing experience on its own. The sundeck is complimented
                with a lounge and bar that serves an exquisite selection of
                bites and cocktails that set the tone for a laid-back day
                onboard.
              </p>
            </Fade>
          </Grid> */}
              {data1?.experience.map((item, index) => {
                return (
                  <>
                    {windowSize > 1099 ? (
                      index % 2 === 0 ? (
                        <>
                          {index !== 0 ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                md={index === 0 ? 8 : 5}
                                style={{ color: "#2F3D4C" }}
                                className="     "
                              >
                                <Fade>
                                  <h3 style={{ fontFamily: "SnellRoundhand" }} className="centerText">
                                    {item.title}
                                  </h3>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    className={`${index === 0 ? "centerText" : ""}`}
                                  ></div>
                                </Fade>
                              </Grid>
                              <Grid item xs={12} sm={8} md={index === 0 ? 8 : 6} className="  my-4">
                                <Fade>
                                  <SmallCarosal myArr={item.photos ? item.photos : item.image} height={"370px"} />
                                </Fade>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sm={8} md={index === 0 ? 8 : 6} className="mb-4 ">
                                <Fade>
                                  <SmallCarosal myArr={item.photos ? item.photos : item.image} height={"370px"} />
                                </Fade>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                md={index === 0 ? 8 : 5}
                                style={{ color: "#2F3D4C" }}
                                className="    "
                              >
                                <Fade>
                                  <h3 style={{ fontFamily: "SnellRoundhand" }} className="centerText">
                                    {item.title}
                                  </h3>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    className={`${index === 0 ? "centerText" : ""}`}
                                  ></div>
                                </Fade>
                              </Grid>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={8} md={6} className="  my-4">
                            <SmallCarosal myArr={item.photos} height={"370px"} style={{ border: "1px solid green" }} />
                          </Grid>
                          <Grid item xs={12} sm={8} md={5} style={{ color: "#2F3D4C" }} className="    ">
                            <Fade>
                              <h3 style={{ fontFamily: "SnellRoundhand" }} className="centerText">
                                {item.title}
                              </h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </Fade>
                          </Grid>
                        </>
                      )
                    ) : (
                      <>
                        <Grid item xs={12} sm={8} md={5} className="    my-4">
                          <Fade>
                            <SmallCarosal myArr={item.photos} height={"370px"} />
                          </Fade>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} style={{ color: "#2F3D4C" }} className="     ">
                          <Fade>
                            <h3 style={{ fontFamily: "SnellRoundhand" }} className="centerText">
                              {item.title}
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </Fade>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
            </Grid>
          </Container>
        </div>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default RelaxAndUnwind;
