import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fade } from "react-reveal";
import { useLocation } from "react-router-dom";

const PagesCarosal = ({ myArr, height, margin, width }) => {
  const loc = useLocation();
  const settings = {
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    dots: true,
    responsive: [
      {
        breakpoint: 1234,
        settings: {
          infinite: true,
          fade: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          infinite: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <div
      style={{
        width: "100%",
        height: `${height ? height : "380px"}`,
        cursor: "pointer",
        margin: 0,
        overflow: "hidden",
      }}
      className=" heightSora"
    >
      <Slider
        {...settings}
        className={` SmallSlider`}
        style={{
          padding: "0px",
          height: "100%",
          width: "100%",
        }}
      >
        {myArr?.map((item) => {
          return (
            <div
              style={{
                height: `${height ? height : "800px"} `,
                width: "100%",
              }}
              className="heightSora"
            >
                <img
                  src={item.photo ? item.photo : item.media ? item.media : item}
                  alt=""
                  height={"100%"}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                  // className={`${width === "w" && "w100"}`}
                  className="heightSora"
                />
              <p
                style={{
                  position: "absolute",
                  top: "75%",
                  // right: "50%", // Adjust this value as needed
                  marginLeft:"40px",
                  width:"fit-content",
                //   transform: "translateX(-50%)", // Center the title horizontally
                //   zIndex: "2",
                  color: "#E8E5E2",
                  fontSize: "40px",
                  fontFamily: "SnellRoundhand1",
                }}
                className="title33"
              >
                {item.title && item.title}
              </p>
              )
            </div>
          )
        })}
      </Slider>
    </div>
  );
};

export default PagesCarosal;
