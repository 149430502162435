import React from "react";

const TermsAndConditionsComp = ({ title, descriptions }) => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <h2 style={{ fontFamily: "SnellRoundhand" }}>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: descriptions }} />
    </div>
  );
};

export default TermsAndConditionsComp;
