import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import baseUrl from "./BaseUrl";
import { useTranslation } from "react-i18next";
import GetBaseUrl from "./BaseUrl";

const FetchData = (Url) => {
  const [data, setData] = useState(null);
  const base = GetBaseUrl();
  console.log("baseeeee", base + Url);
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(base + Url);
        console.log("Response data:", response.data);
        setData(response.data);
      } catch (err) {
        console.log("Error:", err);
        setData(null);
      }
    })();
  }, [Url]);

  return data;
};

export default FetchData;
