import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BookinForm from "./BookinForm";
import { Fade } from "react-reveal";

const style = {
  position: "absolute",
  top: '50%',
  right: '0.5%',
  transform: 'translate(0, -50%)',
  width: 270,
  display: "flex",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 1.4,
  zIndex: "39858548748643733",
  borderRadius: "20px",
};

export default function BookingModal({ handleOpen, handleClose, open }) {
  return (
    <div>
      {/* <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <BookinForm handleClose={handleClose} />
        </Box>
      </Modal> */}
    </div>
  );
}