import React from "react";
import { Paper, Button } from "@mui/material";
import SmallCarosal from "./SmallCarosal";
import dataAccomo from "../AccomoDataSliders";
import PagesCarosal from "../PagesCarosal";
import { useLocation } from "react-router-dom";

export default function Example(props) {
  const locat = useLocation();
  return (
    <div style={{ width: "100%",  position: "relative" }}>
      {props.carosal !== "yes" ? (
        <>
          <div
            className="w-100"
            style={{
              position: "relative",
            }}
          >
            {locat.pathname !== "/DockPlan" && (
              <img
                src={props.image}
                alt=""
                style={{
                  width: "100%",
                  height: `${props.title ? (props.description === "no" ? "93vh" : "79vh") : "100vh"}`,
                  objectFit: "cover",
                }}
                className="heightSora"
              />
            )}

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                background: "radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          </div>
          {props.title && (
            <div
              className="title"
              style={{
                position: "relative",
                zIndex: "10",
                fontFamily: "SnellRoundhand",
                textAlign: "center",
                background: "#e8e5e2",
                padding: "8px",
                width: "814px",
                margin: "auto",
                marginTop: "-30px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  color: "#2F3D4C",
                }}
              >
                {props.title}
              </h1>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: `${props.title ? (props.description === "no" ? "93vh" : "79vh") : "100vh"}`,
              width: "100%",
              // marginTop: "-60px",
              position: "relative",
            }}
            className="w-100 heightSora"
          >
            {/* <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: "8",
                background:
                  "radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div> */}
            <PagesCarosal myArr={props.arr} height="100%" margin="yes" />
          </div>
          {props.title && (
            <div
              className="title"
              style={{
                position: "relative",
                zIndex: "10",
                fontFamily: "SnellRoundhand",
                textAlign: "center",
                background: "#e8e5e2",
                padding: "10px",
                width: "814px",
                margin: "auto",
                marginTop: "-30px",
                borderRadius: "10px",
              }}
            >
              <h1 style={{ color: "#2F3D4C" }}>{props.title}</h1>
            </div>
          )}
        </>
      )}
      {props.title3 && (
        // <p
        //   style={{
        //     position: "absolute",
        //     top: "80%",
        //     left: "100px",
        //     zIndex: "2",
        //     color: "#E8E5E2",
        //     fontSize: "40px",
        //     fontFamily: "SnellRoundhand1",
        //   }}
        //   className="inharm"
        // >
        //   {props.title3}
        // </p>
        <></>
      )}
    </div>
  );
}
