import { useTranslation } from "react-i18next";

const GetBaseUrl = () => {
  const { i18n } = useTranslation();
  let lang = i18n.language;
  console.log('i18n',i18n)
  if (lang === "en-US") {
    lang = "en";
  }
  if (lang !== "en-US" && lang !== "en" && lang !== "es" && lang !== "pt") {
    lang = "en";
  }
  const baseUrl = `https://admin.adnilecruises.com/${lang}/api/`;
  return baseUrl;
};
export default GetBaseUrl;
