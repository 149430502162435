import React from "react";
import Example from "../Components/Carousel";
import { Container } from "@mui/material";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const DockPlan = () => {
  const deckImage = FetchData("decks")?.decks?.[0]?.file;
  return deckImage ? (
    <>
      <Example image="/imageSlider.png" />
      <Container maxWidth="md">
        <div className="flex" style={{ padding: "25px", background: "#fff", marginTop: "150px" }}>
          <div>
            <img src={deckImage} style={{ width: "100%" }} alt="" />
          </div>
        </div>
      </Container>
    </>
  ) : (
    <div style={{ width: "100%", height: "100vh" }} className="flex">
      <Loading />
    </div>
  );
};

export default DockPlan;
