import React, { useRef, useState } from "react";
import Example from "../Components/Carousel";
import { Button, Container, Grid } from "@mui/material";
import ContactInfo from "../Components/ContactInfo";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
import { BiLoaderAlt } from "react-icons/bi";
import GetBaseUrl from "../BaseUrl";

const ContactUs = () => {
  const textareaRef = useRef();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const pages = FetchData("pages/20");
  const formRef = React.useRef();
  const base = GetBaseUrl();
  function handleFocus() {
    textareaRef.current.selectionStart = 0;
  }
  const [formstate, setfromstate] = useState({
    name: "",
    title: "",
    email: "",
    phone:"",
    whatsapp_number:"",
    subject: "",  
  });
  const formHandler = (e) => {
    setfromstate({...formstate, [e.target.name]: e.target.value });
  };

 
  const submitdata = (e) => {
    e.preventDefault();
    console.log("formstate",formstate);
    setLoading(true);
    axios.post(base+'contactus', formstate, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, PATCH",
        },
      })
      .then((res) => {
        toast.success("Successfully Sent !", {
          position: toast.POSITION.TOP_CENTER,
        });
        setfromstate({
          name: "",
          title: "",
          email: "",
          phone:"",
          whatsapp_number:"",
          subject: "",
        });
        formRef.current.reset();
      })
      .catch((err) => {
        
        if(err.message==="Network Error"){
          toast.success("Successfully Sent !", {
            position: toast.POSITION.TOP_LEFT,
          });
        }else{
          toast.error(err.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {pages !== null ? (
        <div>
          <ToastContainer />
          <Example title={pages?.pages[0].title} carosal="yes" arr={pages?.pages[0].photos} mg={true} />
          <Container maxWidth="lg" className="my-4">
            <form onSubmit={submitdata} ref={formRef}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{
                  color: " #E8E5E2",
                }}
                spacing={3}
                className="Accomod"
              >
                <Grid item xs={12} sm={8} md={4} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="text"
                    className="contactInp"
                    placeholder={t("ContactUs.Name")}
                    name="name"
                    onChange={formHandler}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={4} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="text"
                    className="contactInp"
                    placeholder={t("ContactUs.Subject")}
                    name="subject"
                    onChange={formHandler}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={4} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="email"
                    className="contactInp"
                    placeholder={t("ContactUs.Email")}
                    name="email"
                    onChange={formHandler}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={6} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="text"
                    className="contactInp"
                    placeholder={t("ContactUs.phone")}
                    name="phone"
                    onChange={formHandler}
                   
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={6} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="text"
                    className="contactInp"
                    placeholder={t("ContactUs.whatsUp")}
                    name="whatsapp_number"
                    onChange={formHandler}
 
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={12} style={{ color: "#28374e" }} className="flex">
                  <input
                    type="textarea"
                    className="contactInp"
                    placeholder={t("ContactUs.Message")}
                    style={{ height: "200px", width: "100%" }}
                    ref={textareaRef}
                    onFocus={handleFocus}
                    name="title"
                    onChange={formHandler}
                    required={true}
                  />
                </Grid>
                
                <Grid item xs={12} sm={8} md={4} style={{ color: "#28374e" }} className="flex">
                  <button
                    style={{
                      background: "#2F3D4C",
                      width: "200px",
                      color: "#E8E5E2",
                      fontSize: "24px",
                      borderRadius: "24px",
                    }}
                    className="px-4 py-2"
                    type="submit"
                  >
                    {loading ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <>
                        {t("ContactUs.Submit")}
                        <img src="/vectorr.png" alt="" className="mx-2" />
                      </>
                    )}
                  </button>
                </Grid>
              </Grid>
            </form>
            <ContactInfo />
          </Container>
        </div>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default ContactUs;
