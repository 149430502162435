import { Container, Grid } from "@mui/material";
import React from "react";
import ItinerariesComp from "../Components/ItinerariesComp";
import Example from "../Components/Carousel";
import { Fade } from "react-reveal";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const Itineraries = () => {
  const iter = FetchData("pages/13");
  const esc = FetchData("escapades");
  const insp = FetchData("inspirations");
  console.log({itre:iter})
  return (
    <div>
      {iter !== null && esc !== null && insp !== null ? (
        <>
          <Example title={iter?.pages[0].title} carosal="yes" arr={iter?.pages[0].photos} mg={true} />
          <Container maxWidth="md">
            <div
              dangerouslySetInnerHTML={{
                __html: iter?.pages[0].description,
              }}
              className="centerText coler mb-0"
            ></div>
          </Container>
          <Container maxWidth="lg" className="my-5">
           <Grid
              container
              direction="row"
              style={{
                color: "#E8E5E2",
                justifyContent: "center",
              }}
              spacing={5}
            >
              <Grid item xs={12} sm={8} md={5} style={{ color: "#28374e" }}>
                <Fade delay={200}>
                  <ItinerariesComp image="/Itenaries2.png" desc="Tailored Journeys Inspiration" esc={esc} />
                </Fade>
              </Grid>
              <Grid item xs={12} sm={8} md={5} style={{ color: "#28374e" }}>
                <Fade>
                  <ItinerariesComp
                    image="/Itenaries1.png"
                    desc="Monumental Escapades  
          5 - Nights Itinerary"
                    ins={insp}
                  />
                </Fade>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Itineraries;
