import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";
import SmallCarosal from "./SmallCarosal";

const Hospitality = ({ myarr }) => {
  const dataHomeReg = ["/Mask.png", "/Mask.png", "/Mask.png"];

  return (
    <Container maxWidth="lg">
      <Fade>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "5px 30px",
            color: "#2F3D4C",
            minHeight: "50vh",
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={8} md={5.5}>
            <img src={myarr[3].photos[0]?.photo} alt="" width={"100%"} />
          </Grid>
          <Grid item xs={12} sm={8} md={5} className="flexCol" style={{ minHeight: "20vh", width: "100%" }}>
            <h2
              style={{
                textAlign: "center",
                fontFamily: "SnellRoundhand",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: myarr[3].title,
                }}
                className="centerText coler"
              ></div>
            </h2>
            <div className="Imagine">
              <div
                dangerouslySetInnerHTML={{
                  __html: myarr[3].description,
                }}
                className=""
              ></div>
            </div>
          </Grid>
        </Grid>
      </Fade>
    </Container>
  );
};

export default Hospitality;
