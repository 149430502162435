import { Container, Grid } from "@mui/material";
import React from "react";
import Escapades from "../EscapedsData";
import Example from "../Components/Carousel";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const Escapeds = (props) => {
  const days = FetchData("escapadesDetails");
  const pages = FetchData("pages/15");
  return (
    <>
      {days !== null && pages !== null ? (
        <>
          <Example arr={pages?.pages[0].photos} carosal="yes" title={pages?.pages[0].title} description="no" />

          <Container maxWidth="lg" className="my-4">
            <Grid
              container
              direction="row"
              style={{
                color: " #2F3D4C",
                justifyContent: "space-evenly ",
              }}
            >
              <Grid item xs={12} sm={8} md={2.5} className="flexCol" style={{ justifyContent: "space-between" }}>
                {days?.escapadedetails.slice(0, 3).map((item) => {
                  return (
                    <div className="mb-4">
                      <h2 style={{ fontFamily: "SnellRoundhand" }}>{item.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                        className=""
                      ></div>
                    </div>
                  );
                })}
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={2.5}
                className="flexCol "
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {days?.escapadedetails.slice(3, 6).map((item) => {
                    return (
                      <div className="mb-4">
                        <h2 style={{ fontFamily: "SnellRoundhand" }}>{item.title}</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                          className=""
                        ></div>
                      </div>
                    );
                  })}
                </div>
                <div className="mb-3">
                  <Link to="/ContactUs" style={{ opacity: "1", color: "#fff" }}>
                    <Button
                      className=" px-5 "
                      style={{ backgroundColor: "#2F3D4C", color: "#fff" }}
                      onClick={props.handleOpen}
                    >
                      Book Now
                    </Button>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6} sm={8} md={2.5}>
                <div style={{ width: "100%", height: "100%", overflow: "hidden" }} className="flex">
                  <img src="/escap2.png" alt="" width="100%" height="550px" className="anime" />
                </div>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default Escapeds;
