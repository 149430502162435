import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function ExperiencesCard({ img, description, title }) {
  const nav = useNavigate();
  const getCorrectedImagePath = (img) => {
    // Replace backslashes with forward slashes
    const correctedPath = img.replace(/\\/g, "/");
    return correctedPath;
  };
  return (
    <Card
      sx={{ maxWidth: "500px", minHeight: "500px" }}
      style={{
        textAlign: "center",
        background: "transparent",
        boxShadow: " 0px 0px 0px",
      }}
      onClick={() => nav("/Experience")}
    >
      <div style={{ height: "360px", overflow: "hidden" }}>
        {img && (
          <CardMedia
            sx={{ height: "100%" }}
            image={getCorrectedImagePath(img)}
            title="green iguana"
            style={{ borderRadius: "10px" }}
            className="anime"
          />
        )}
      </div>
      <CardContent style={{ color: "#E8E5E2" }}>
        <Typography gutterBottom variant="h4" component="div" style={{ fontFamily: "SnellRoundhand" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          ></div>
        </Typography>
        <Typography variant="body1">
          <div
            style={{ maxHeight: "100px", overflow: "hidden" }}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className="wellnes"
          ></div>
        </Typography>
      </CardContent>
    </Card>
  );
}
