import { Container } from "@mui/material";
import React from "react";
import { useState } from "react";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";

const AccomoCusSlide = ({ obj, name }) => {
  const [i, setI] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  console.log("objjjjjjjjjjjjjjjjjjjjjjj", obj);
  const handlePrev = () => {
    setFadeIn(true);
    setTimeout(() => {
      setI(i === 0 ? obj.length - 1 : i - 1);
      setFadeIn(false);
    }, 300); // Adjust the timeout duration to match your desired fade transition duration
  };

  const handleNext = () => {
    setFadeIn(true);
    setTimeout(() => {
      setI(i === obj.length - 1 ? 0 : i + 1);
      setFadeIn(false);
    }, 300); // Adjust the timeout duration to match your desired fade transition duration
  };

  return (
    <>
      <div className="flex flex1">
        <div
          style={{
            backgroundColor: "#2f3d4c",
            width: "500px",
            height: "320px",
            padding: "30px",
            marginRight: "-50px",
            zIndex: "3",
            borderRadius: "20px",
            transition: "opacity 0.3s ease",
            opacity: fadeIn ? 0 : 1,
          }}
          className="flexCol conSlide my-2"
        >
          <div>
            <h3
              style={{
                fontFamily: "SnellRoundhand",
                textAlign: "center",
                color: "#E8E5E2",
              }}
            >
              {obj[i].title}
            </h3>
            <p style={{ color: "#E8E5E2" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: obj[i].description,
                }}
                className=""
              ></div>
            </p>
          </div>
          <div className="flex slideBut">
            <button onClick={handlePrev}>
              <GrPrevious />
            </button>
            <button onClick={handleNext} style={{ marginLeft: "10px" }}>
              <GrNext />
            </button>
          </div>
        </div>
        <div>
          <img
            src={obj[i].photo}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "20px",
              width: "680px",
              height: "400px",
              transition: "opacity 0.3s ease",
              opacity: fadeIn ? 0 : 1,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AccomoCusSlide;
