import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Data from "../AccomodationData";
import { Fade } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import SmallCarosal from "./SmallCarosal";
import SliderAccomo from "./SliderAccomo";

const AccomodationBooking = (props) => {
  const navigate = useNavigate();
  return (
    <>
      {props.cabins?.Cabins.map((item, index) => {
        return (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              style={{
                color: " #E8E5E2",
                marginBottom: "20px",
              }}
              className="Accomod"
            >
              <Grid item xs={12} sm={8} md={6} style={{ height: "300px" }} className="">
                <SliderAccomo myArr={item.photos} height="100%" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                className="flexCol kalam"
                style={{
                  justifyContent: "space-between",
                  minHeight: "300px",
                }}
              >
                <Fade delay={200}>
                  <div className="mb-4">
                    <h3 style={{ color: "#2F3D4C" }} className="snell mb-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}
                        className="habd"
                      ></div>
                    </h3>
                    <p style={{ fontSize: "19px", color: "#2F3D4C" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                        className="habd"
                      ></div>
                      <Link to={`/Accomodation/${item.id}`} style={{ color: "#2F3D4C", fontWeight: "bold" }}>
                        Explore More
                      </Link>
                    </p>
                  </div>

                  <div
                    className="flex"
                    style={{
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <a
                      className="px-5 py-1"
                      style={{
                        backgroundColor: "#2F3D4C",
                        color: "#E8E5E2",
                      }}
                      // onClick={() => navigate("/ContactUs")}
                      href="https://booking.aqua-cruises.com/booksearch"
                    >
                      Book Now
                    </a>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default AccomodationBooking;
