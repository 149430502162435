const Media = [
  {
    image: "/gallery1.png",
  },
  {
    image: "/gallery2.png",
  },
  {
    image: "/gallery3.png",
  },
  {
    image: "/gallery4.png",
  },
  {
    image: "/gallery5.png",
  },
  {
    image: "/gallery6.png",
  },
  {
    image: "/gallery7.png",
  },
  {
    image: "/gallery8.png",
  },
  {
    image: "/gallery8.png",
  },
];
export default Media;
