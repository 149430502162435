import React, { useEffect } from "react";
import Example from "../Components/Carousel";
import { Container, Grid } from "@mui/material";
import Button from "@mui/material/Button";

import theLists from "../ListsAcco";
import { Link, useParams } from "react-router-dom";
import AccomoCusSlide from "../Components/AccomoCusSlide";
import informationAccData from "../AccomoSlideData";
import Data from "../AccumSlideData";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
const Accomodation = ({ AcomoSwitch, handleOpen }) => {
  const cat = ["/cabina.png", "/cabina.png", "/cabina.png"];
  const { id } = useParams();
  const cabin = FetchData(`cabins/${id}`);
  const pages = FetchData("pages/23");
  const imagesArr = cabin?.sliders?.map((slider) => slider?.photo);
  return cabin ? (
    <>
      <Example title={cabin?.title} carosal="yes" arr={imagesArr} mg={true} description="no" />
      <Container maxWidth="lg" className="my-4">
        <div className="flex justify-content-center mb-4"></div>
        {/* <AccomoCusSlide obj={cabin?.cabinfeatures}  /> */}
        {cabin?.cabinfeatures.length > 0 && <AccomoCusSlide obj={cabin?.cabinfeatures} />}
        <Container maxWidth="md" className="mt-4">
          <div className="flex">
            <div className="line" style={{ flex: "2" }}></div>
            <h2 className="local-info mt-4">Facilities and Activities</h2>
            <div className="line" style={{ flex: "2" }}></div>
          </div>
        </Container>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{
            color: " #E8E5E2",
          }}
          className="Accomod"
        >
          {cabin?.categories?.map((item) => {
            return (
              <Grid
                item
                xs={8}
                sm={4}
                md={3.1}
                style={{
                  color: "#28374e",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <h5 style={{ fontWeight: "700" }}>{item.title}</h5>
                  {item?.facilites?.map((item1) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }} className="my-3">
                        <img src={item1?.photos?.[0]?.photo} alt="" className="mx-2" style={{width:'30px'}}/>
                        <div>{item1.title}</div>
                      </div>
                    );
                  })}
                </div>
              </Grid>
            );
          })}
          <div
            className=" w-70 "
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginBottom: "20px",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Button className=" px-5 " style={{ backgroundColor: "#2F3D4C" }}>
              <Link to="/Accomodation" style={{ opacity: "1", color: "#fff" }}>
                View Other Cabins
              </Link>
            </Button>

            <Button className=" px-5 " style={{ backgroundColor: "#2F3D4C" }} onClick={handleOpen}>
              <Link to="https://bookings.travelclick.com/116165?languageid=1#/guestsandrooms" style={{ opacity: "1", color: "#fff" }}>
                Book Now
              </Link>
            </Button>
          </div>
        </Grid>
      </Container>
    </>
  ) : (
    <div style={{ width: "100%", height: "100vh" }} className="flex">
      <Loading />
    </div>
  );
};

export default Accomodation;
{
  /* <Grid
item
xs={12}
sm={8}
md={6}
style={{ border: "1px solid black", color: "#28374e" }}
>
<Grid
  item
  xs={12}
  sm={8}
  md={12}
  style={{ border: "1px solid black", color: "#28374e" }}
>
  <img src={Data[0].image} style={{ width: "100%" }} alt="" />
</Grid>
</Grid>
<Grid
item
xs={12}
sm={8}
md={6}
style={{ border: "1px solid black", color: "#28374e" }}
>
{Data?.slice(1).map((item, index) => {
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={index === Data.length - 1 ? 6 : 3}
      style={{ border: "1px solid black", color: "#28374e" }}
    >
      <img src={item.image} style={{ width: "100%" }} alt="" />
    </Grid>
  );
})}
</Grid> */
}
{
  /* <Container maxWidth="lg" className="my-4">
<Grid
  container
  direction="row"
  alignItems="center"
  justifyContent="center"
  style={{
    color: " #E8E5E2",
  }}
  spacing={1}
  className="Accomod"
>
  <Grid
    item
    xs={12}
    sm={8}
    md={3}
    style={{ background: "#2F3D4C", height: "13vh" }}
    className="m-2"
  >
    <p>
      Nile View Rooms emanated with natural sunlighting and air
      ventilation, elevating positive energy from the surrounding
      nature.
    </p>
  </Grid>
  <Grid
    item
    xs={12}
    sm={8}
    md={3}
    style={{ background: "#2F3D4C", height: "13vh" }}
    className="m-2"
  >
    <p>
      The purest organic and ecological fabrics are tailored for an
      energizing sleep.
    </p>
  </Grid>
  <Grid
    item
    xs={12}
    sm={8}
    md={3}
    style={{ background: "#2F3D4C", height: "13vh" }}
    className="m-2"
  >
    <p>Natural handmade furnishing and adornments.</p>
  </Grid>{" "}
</Grid>
<AccomodationBooking />
</Container> */
}
