import React from "react";
import Media from "../MediaCenterGallery";
import { Button, Container, Grid } from "@mui/material";
import Example from "../Components/Carousel";
import GalleryModal from "../Components/GalleryModal";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../App";

const MediaCenter = () => {
  const [openGallery, setOpenGallery] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(false);
  const handleOpen = () => setOpenGallery(true);
  const handleClose = () => setOpenGallery(false);
  const pages = FetchData("pages/17");
  const gallery = FetchData("galleries");
  const attachments = FetchData("attachments")?.attachments?.[0];
  const contactInfo = useContext(AppContext)?.contactInfo?.[0];
console.log('pages',pages)
  return (
    <>
      {pages !== null && gallery !== null ? (
        <>
          <Example title={pages?.pages[0].title} carosal="yes" arr={pages?.pages[0].photos} mg={true} />
          <Container maxWidth="sm" className="">
            <p style={{ color: "#2f3d4c", fontSize: "18px" }} className="centerText">
              <div
                dangerouslySetInnerHTML={{
                  __html: pages?.pages[0].description,
                }}
              ></div>
            </p>
          </Container>
          <Container maxWidth="md" className="my-5">
            <div className="grid-container">
              {gallery?.galleries.map((item, index) => {
                return (
                  <div
                    className={`${
                      index === Media.length - 1 || index === 0 || index === 3 || index === 2 ? `index${index}` : ""
                    }  `}
                    onClick={() => {
                      handleOpen();
                      setSlideIndex(index);
                    }}
                  >
                    <img
                      src={item.photo}
                      width="100%"
                      alt=""
                      height="100%"
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      className="soraMed"
                    />
                  </div>
                );
              })}
            </div>
            <GalleryModal
              setOpenGallery={setOpenGallery}
              openGallery={openGallery}
              handleClose={handleClose}
              handleOpen={handleOpen}
              slideIndex={slideIndex}
            />
            <div className="flex my-4">
              <a href={attachments?.factsheet} target="_blank">
                <Button style={{ background: "#2F3D4C", color: "white" }} className="mx-3">
                  Download our fact sheet
                </Button>
              </a>

              <a href={attachments?.itinerary} target="_blank">
                <Button style={{ background: "#2F3D4C", color: "white" }} className="mx-3">
                  Download our itinerary
                </Button>
              </a>
              <a href={attachments?.sightseeing} target="_blank">
                <Button style={{ background: "#2F3D4C", color: "white" }} className="mx-3">
                  Sightseeing Booklet
                </Button>
              </a>
            </div>
            <div className="flex my-5">
              <div style={{ marginRight: "30px" }}>
                <img src={contactInfo?.qr1} alt="" style={{width:'100px'}}/>
                <p style={{ textAlign: "center" }}>Luxor</p>
              </div>
              <div>              
                <img src={contactInfo?.qr2} alt="" style={{width:'100px'}}/>
                <p style={{ textAlign: "center" }}>Aswan</p>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default MediaCenter;
