import { Container, Grid } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import { TiLocation, TiZoomOutline } from "react-icons/ti";
import { AppContext } from "../App";
const LocalInformation = () => {
  const contactInfo = useContext(AppContext)?.contactInfo?.[0];
  const weather = useContext(AppContext)?.weather;
  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          padding: "5px 30px",
          color: "#2F3D4C",
          minHeight: "20vh",
        }}
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={9.5} style={{ padding: "0" }} className="mb-3">
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              style={{
                color: "#2F3D4C",
                minHeight: "20vh",
              }}
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={6}>
                <h3 className="snell">Location</h3>
                <h5>Private Docking Ground</h5>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                  <img src={contactInfo?.qr1} alt="" style={{width:'100px'}}/>
                    <p style={{ textAlign: "center" }}>Luxor</p>
                  </div>
                  <div>                
                    <img src={contactInfo?.qr2} alt="" style={{width:'100px'}}/>
                    <p style={{ textAlign: "center" }}>Aswan</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <h3 className="snell">Languages</h3>
                <p>
                  Arabic is the native speaking language in Egypt. Most Egyptians speak English and other languages
                  including French, Spanish & German.
                </p>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <h3 className="snell">Temperature</h3>
                <img src="sun.png" alt="" style={{ width: "80px" }} className="mx-3" />
                <span style={{ fontSize: "21px" }}>{weather?.main?.temp?.toFixed()}</span>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <h3 className="snell">Time Zone</h3>
                <p>GMT to Egypt: {contactInfo?.gmt} Hours</p>
                <p>CET to Egypt: {contactInfo?.cet} Hours</p>
              </Grid>
              <Grid item xs={12} sm={6} md={7} style={{ color: "#28374e" }}>
                <div className="flex" style={{ justifyContent: "flex-start" }}>
                  <h2 className="snell">Contact us</h2>
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    margin: "0",
                    alignItems: "flex-start",
                    display: "flex",
                  }}
                >
                  <div>
                    <TiLocation /> Address : {contactInfo?.adress}
                    <br />
                    {/* <TiZoomOutline /> Working Hours : {contactInfo?.working_hours}
                    <br /> */}
                    <a href={`tel: ${contactInfo?.phone}`} style={{ color: "#28374e" }}>
                      <MdPhone /> {contactInfo?.phone}
                    </a>
                    <br />
                    <a href={`mailto: ${contactInfo?.email}`} style={{ color: "#28374e" }}>
                      <MdEmail /> {contactInfo?.email}
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={5} sm={3} md={2.3} style={{ padding: "0" }}>
          <img src="Tourism.png" alt="" style={{ width: "100%", margin: "0" }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LocalInformation;
