import React from "react";
import Terms from "../TermsData";
import TermsAndConditionsComp from "../Components/TermsAndConditionsComp";
import { Container, Grid } from "@mui/material";
import Example from "../Components/Carousel";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";

const TermsAndConditions = () => {
  const condition = FetchData("condtions");
  const pages = FetchData("pages/19");
  return (
    <>
      {condition !== null ? (
        <>
          <Example
            title={pages?.pages[0].title}
            carosal="yes"
            arr={pages?.pages[0].photos}
            mg={true}
            description="no"
          />

          <Container maxWidth="lg" className="my-4">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{
                color: " #E8E5E2",
              }}
            >
              {condition?.conditions.map((item) => {
                return (
                  <Grid item xs={12} sm={10} md={12} style={{ color: "#28374e" }}>
                    <TermsAndConditionsComp descriptions={item.description} title={item.title} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default TermsAndConditions;
