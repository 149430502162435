import { Container, Grid } from "@mui/material";
import React from "react";
import Example from "./Carousel";

const ExperienceRelax = () => {
  return (
    <>
    <Example image="cyan2.png"/>
      <Container maxWidth="lg" className="my-4">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          style={{
            color: " #E8E5E2",
          }}
          spacing={6}
          className="Accomod"
        >
          <Grid item xs={12} sm={8} md={8} style={{ color: "#28374e" }} >
            <h3 style={{textAlign:"center"}}>Relax And unWind</h3>
            <div className="my-4">
              <p style={{ textAlign: "center" }}>
                Venture through the mesmerizing cities of Luxor and Aswan, and
                indulge in the breathtaking views of the Nile accompanied by
                historic grandeur. Relish in customized itineraries with ample
                time to unwind onboard.
              </p>
              <img src="relax.png" alt="" style={{ width: "100%" }} />
            </div>
            <div>
              <p style={{ textAlign: "center" }}>
                Venture through the mesmerizing cities of Luxor and Aswan, and
                indulge in the breathtaking views of the Nile accompanied by
                historic grandeur. Relish in customized itineraries with ample
                time to unwind onboard.
              </p>
              <img src="relax.png" alt="" style={{ width: "100%" }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={2} style={{ color: "#28374e" }}>
            <img
              src="cyan.png"
              alt=""
              style={{ height: "100vh", width: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ExperienceRelax;
