import { Grid } from "@mui/material";
import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import { TiLocation, TiZoomIn, TiZoomOutline } from "react-icons/ti";
import { AppContext } from "../App";
import { useContext } from "react";
import aswan from '../fonts/aswan.jpeg';
import luxor from '../fonts/luxor.jpg';
const ContactInfo = () => {
  const contactInfo = useContext(AppContext)?.contactInfo?.[0];
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        color: " #E8E5E2",
        marginTop: "50px",
      }}
      spacing={3}
      className="Accomod"
    >
      <Grid item xs={12} sm={8} md={6} style={{ color: "#28374e" }}>
        <div className="flex" style={{ justifyContent: "flex-start" }}>
          <h2 className="snell">Contact info</h2>
        </div>
        <div
          style={{
            fontSize: "18px",
            margin: "0",
            alignItems: "flex-start",
            display: "flex",
          }}
        >
          <div>
            <TiLocation /> Address : {contactInfo?.adress}
            <br />
            {/* <TiZoomOutline /> Working Hours : {contactInfo?.working_hours}
            <br /> */}
            <a href={`tel: ${contactInfo?.phone}`} style={{ color: "#28374e" }}>
              <MdPhone /> {contactInfo?.phone}
            </a>
            <br />
            <a href={`mailto: ${contactInfo?.email}`} style={{ color: "#28374e" }}>
              <MdEmail /> {contactInfo?.email}
            </a>
          </div>
        </div>
        <h3 className="snell mt-5">Time Zone</h3>
        <p>GMT to Egypt: {contactInfo?.gmt} Hours</p>
        <p>CET to Egypt: {contactInfo?.cet} Hours</p>
      </Grid>
      <Grid item xs={12} sm={8} md={5} style={{ color: "#28374e" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            color: " #E8E5E2",
            marginTop: "50px",
          }}
          spacing={3}
          className="Accomod"
        >
          <Grid item xs={10} sm={6} md={6} style={{ color: "#28374e" }}>
            <h3 style={{ fontFamily: "SnellRoundhand" }}>Private Docking Ground</h3>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "30px" }}>
             
              <img src={contactInfo?.qr1} alt="" style={{height:'100px'}}/>
                <p style={{ textAlign: "center" }}>Luxor</p>
              </div>
              <div>
                 <img src={contactInfo?.qr2} alt="" style={{height:'100px'}}/>
                <p style={{ textAlign: "center" }}>Aswan</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="contact-container" style={{ color: "#28374e" }}>
            {/* <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58277.12999699866!2d32.87890567280838!3d24.090217648640028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14367b5ab1a30e5f%3A0xfb109fe337cabccb!2sAswan%2C%20Aswan%20Governorate!5e0!3m2!1sen!2seg!4v1693470394460!5m2!1sen!2seg"
              width="100%"
              height="150"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <a href="https://www.google.com/maps/place/HISTORIA+the+Boutique+Hotel+Nile+Cruise/@25.7146743,32.7177066,12z/data=!4m9!3m8!1s0x144915501f837c63:0x1af6a2dc718c3c82!5m2!4m1!1i2!8m2!3d25.714691!4d32.6476663!16s%2Fg%2F11qh2638gh?hl=ar&entry=ttu" target="_blank">
            <img src={luxor} className="imageContact"  alt="luxor"/>
            <div class="text-image-contact">Luxor</div>
            </a>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="contact-container"style={{ color: "#28374e" }}>
            {/* <iframe
            src="https://www.google.com/maps/embed/v1/place?q=Luxor,+Luxor+City,+Luxor,+Egypt&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              width="100%"
              height="150"
              allowFullScreen=""
              loading="lazy"
            
            ></iframe> */}
            <a href="https://www.google.com/maps/place/24%C2%B007'36.5%22N+32%C2%B053'39.1%22E/@24.1268024,32.8941994,17z/data=!4m4!3m3!8m2!3d24.1268024!4d32.8941994?hl=en" target="_blank">
            <img src={aswan} className="imageContact" alt="aswan"/>
            <div class="text-image-contact">Aswan</div>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
