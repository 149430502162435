import React from "react";
import Example from "../Components/Carousel";
import { Container, Grid } from "@mui/material";
import data from "../DiningData";
import DiningComponents from "../Components/DiningComponents";
import { Fade } from "react-reveal";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
import { useTranslation } from "react-i18next";

const Dining = () => {
  const data1 = FetchData("dining");
  const dataHead = FetchData("pages/11");
  const { i18n } = useTranslation();
  // Check if data1 and dataHead are not null
  if (data1 === null || dataHead === null) {
    return (
      <div style={{ width: "100%", height: "100vh" }} className="flex">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <Example title={dataHead?.pages[0].title} carosal="yes" arr={dataHead?.pages[0].photos} mg={true} />
      <Container maxWidth="md" className="mb-5">
        <div
          dangerouslySetInnerHTML={{
            __html: dataHead?.pages[0].description,
          }}
          className={` coler`}
        ></div>
      </Container>
      <Container maxWidth="lg" className="">
        {data1.dining.map((item, index) => (
          <DiningComponents image={item.photos} description={item.description} title={item.title} index={index} />
        ))}
      </Container>
    </div>
  );
};

export default Dining;
