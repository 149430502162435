import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import BookingModal from "./BookingModal";
import { Fade, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Sidebar as Side, Menu as Men, MenuItem as MenI, SubMenu as SubM } from "react-pro-sidebar";
import { AiOutlineCaretDown } from "react-icons/ai";
import Page from "../ExperienceData";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Sidebar } from "react-pro-sidebar";
const drawerWidth = 240;
const langs = [
  { lang: "en-US", Lang1: "en" },
  { lang: "es", Lang1: "es" },
  { lang: "pt", Lang1: "pt" },
];
function DrawerAppBar(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    document.location.reload();
  };
  const FilterLang = langs.filter((item) => {
    return item.lang !== i18n.language;
  });
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [tryMob, setTryMob] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const findLang = langs.find((item, index) => {
    return item.lang === i18n.language;
  });
  const nav1 = useLocation();
  const [lang, setlang] = React.useState(0);
  const drawer = (
    <Box sx={{ textAlign: "center", zIndex: ":8474" }}>
      <Divider />
      <List>
        <div
          className="mt-3 mx-2 flex"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <div onClick={handleDrawerToggle} style={{ cursor: "pointer" }}>
            <CloseIcon style={{ width: "30px", height: "30px" }} />
          </div>
        </div>
        <div className="flex mt-3">
          <img src="/Aquaa.png" alt="" className="" style={{ width: "140px" }} />
        </div>
        <Side>
          <Men
            menuItemStyles={{
              background: "transparent",
            }}
          >
            {t("Navbar", { returnObjects: true })?.map((ele, index) => (
              <>
                {ele.Path === "Accomodation" ? (
                  <SubM label="Accommodation">
                    <div onClick={handleDrawerToggle}>
                      <MenI component={<Link to="/Accomodation" />}>{t("Cabins")}</MenI>
                      <MenI component={<Link to="/DockPlan" />}>{t("Dock")}</MenI>
                    </div>
                  </SubM>
                ) : (
                  <MenI component={<Link to={ele.Path} onClick={handleDrawerToggle} />}>
                    {ele.item === "Overview" ? "Overview" : ele.item}{" "}
                  </MenI>
                )}
              </>
            ))}
            {/* <SubM
              label={`Language :  ${
                i18n.language === "en-US" || i18n.language === "en" ? "EN" : i18n.language.toUpperCase()
              }`}
            >
              <div onClick={handleDrawerToggle}>
                {FilterLang.map((item, index) => {
                  return (
                    <>
                      <MenI
                        onClick={() => {
                          changeLanguage(item.lang);
                        }}
                      >
                        {item.Lang1?.toUpperCase()}
                      </MenI>
                    </>
                  );
                })}
              </div>
            </SubM> */}
             
          
            <div className="dropdown mx-3 ">
              <Link
              
               to="https://booking.adnilecruises.com/booksearch"
                className="flex my-3 btn"
                style={{
                  color: '#ffff',
                  width:'100%',
                  backgroundColor:'#c69956',
                  justifyContent: 'center',
                  padding:'10px',
                  
                }}
              >
              BOOK NOW
              </Link>
              <Link
              to="/ContactUs"
                className="flex btn"
                style={{color: '#282c34',
                 width:'200px',
                  backgroundColor:'#ffff',
                  justifyContent: 'center',
                  padding:'10px'
                }}
              >
              PRIVATE BOOKINGS
              </Link>

            </div>
         
         
            {/* <MenI
              onClick={() => {
                handleDrawerToggle();
                props.handleOpen();
              }}
            >
              Booking
            </MenI> */}
          </Men>
        </Side>
      </List>
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      sx={{
        display: "flex",
        top: "0",
        left: "0",
        width: "100%",
      }}
      style={{ position: "fixed", top: "0", zIndex: "334" }}
    >
      <CssBaseline />
      <AppBar
        component="nav"
        className={`${props.navFade === true ? "omynav" : " mynav"} ${nav1.pathname === "/DockPlan" ? "mynav" : ""}`}
        style={{ padding: "10px 12px " }}
      >
        <Toolbar style={{ justifyContent: "space-between", padding: "10px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              handleDrawerToggle();
              setTryMob(false);
            }}
            sx={{ mr: 0, display: { md: "none" } }}
            style={{ margin: "0" }}
          >
            <MenuIcon />
          </IconButton>
          {props.width ? (
            <Link to="/">
              <img src="/Aquaa.png" alt="" className="imgWidth mobLogo1" style={{ width: "110px" }} />
            </Link>
          ) : (
            <Link to="/">
              <img src="/Aquaa.png" alt="" className="imgWidth moblogo2" style={{ width: "180px" }} />
            </Link>
          )}
          {/* <img src="/RealLogo.png" alt="" style={{ width: props.width }} className="imgWidth" /> */}
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            {Array.isArray(t("Navbar", { returnObjects: true })) &&
              t("Navbar", { returnObjects: true })?.map((ele, index) => (
                <>
                  <Link
                    to={!(ele.Path === "Accomodation") && (ele.Path === "Overview" ? "/" : ele.Path)}
                    key={index}
                    className={`${ele.Path === "Accomodation" ? "dropdown" : ""}`}
                  >
                    <Button
                      id="fade-button"
                      className={`${ele.Path === "Accomodation" ? "dropbtn" : ""}
                    `}
                      disabled={ele.Path === "Accomodation" ? true : false}
                      key={ele.Path}
                      sx={{
                        color: "#fff",
                        margin: "0 12px",
                        "&:disabled": {
                          color: "#fff",
                        },
                        fontWeight: "400",
                      }}
                      onClick={(event) => {
                        ele.Path === "Accomodation" && props.setacomoSwitch(true);
                      }}
                    >
                      <span style={{ opacity: "1" }}>{ele.item}</span>
                    </Button>

                    {ele.Path === "Accomodation" && (
                      <div className={`dropdown-content cabinsSuits ${props.width ? "backGroundCont" : ""}`}>
                        <Link to="/Accomodation">{t("Cabins")} </Link>
                        <Link
                          // onClick={() => {
                          //   props.setacomoSwitch(false);
                          // }}
                          to="/DockPlan"
                        >
                          {t("Dock")}
                        </Link>
                      </div>
                    )}
                  </Link>
                </>
              ))}
          </Box>
         
         
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <div className="dropdown mx-3  flex">
              <Link
                className="flex me-3 btn"
                to="https://booking.adnilecruises.com/booksearch"
               target="_blank"
                style={{color: '#ffff',
            
                  backgroundColor:'#c69956',
                  justifyContent: 'flex-end',
                  padding:'10px',
                  
                }}
              >
              BOOK NOW
              </Link>
              <Link
                to="/ContactUs"
                className="flex btn"
                style={{color: '#282c34',
                  
                  backgroundColor:'#ffff',
                  justifyContent: 'flex-end',
                  padding:'10px'
                }}
              >
              PRIVATE BOOKINGS
              </Link>

            </div>
         
          </Box>

          {/* <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <div className="dropdown mx-3">
              <Button
                className="dropbtn flex"
                style={{ justifyContent: "flex-end" }}
                sx={{
                  color: "#fff",
                  "&:disabled": {
                    color: "#fff",
                  },
                  border: "none",
                  background: "transparent",
                }}
              >
                
                {i18n.language === "en-US" || i18n.language === "en" ? "EN" : i18n.language}
              </Button>
              <div className={`dropdown-content ${props.width ? "backGroundCont" : ""}`}>
                {FilterLang.map((item, index) => {
                  return (
                    <>
                      <Button
                        onClick={() => {
                          changeLanguage(item.lang);
                        }}
                        style={{ color: "#E8E5E2" }}
                      >
                        {item.Lang1}
                      </Button>
                      <img src={item.image} alt="" style={{ width: "30px" }} />
                    </>
                  );
                })}
              </div>
            </div>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onClick={() => {
            tryMob === true && setTryMob(false);
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <BookingModal handleClose={props.handleClose} handleOpen={props.handleOpen} open={props.open} />
    </Box>
  );
}

export default DrawerAppBar;

const facilities = [
  {
    title: "Cabin",
    facilities: [
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
    ],
  },
  {
    title: "Entetainment",
    facilities: [
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
      { text: "Title", icon: "Image" },
    ],
  },
];
